.dot-flashing {
  position: relative;
  left: 27px;
  top: 15px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgb(100 116 139);
  color: rgb(100 116 139);
  animation: dot-flashing 0.6s infinite linear alternate;
  animation-delay: 0.3s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgb(100 116 139);
  color: rgb(100 116 139);
  animation: dot-flashing 0.6s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgb(100 116 139);
  color: rgb(100 116 139);
  animation: dot-flashing 0.6s infinite alternate;
  animation-delay: 0.6s;
}

@keyframes dot-flashing {
  0% {
    background-color: rgb(100 116 139);
  }

  50%,
  100% {
    background-color: rgb(226 232 240);
    ;
  }
}