.chatbot-default-background {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(45deg,
      #cbd5e1 3.57%,
      #ffffff 3.57%,
      #ffffff 50%,
      #cbd5e1 50%,
      #cbd5e1 53.57%,
      #ffffff 53.57%,
      #ffffff 100%);
  background-size: 14px 14px;
}